<template>
  <div id="initial-block">
    <section>
      <div class="Main-frame" >
        <div class="container" style="z-index: 1;">
          <div class="frame-block justify-content-center">
            <div class="frame-info " style=" margin-top: 60px;">
              <div style="text-align: end;">
                <!-- <h1 id="Possibilities" class="main-title mb-4 text-center d-flex justify-content-center align-items-end">
                  {{ translation.translate("LandingPageHome", "frameTitle1") }}
                  <div class="mx-1">
                    <span class="new-price">
                      {{ translation.translate("LandingPageHome", "frameTitle2") }}
                    </span>
                    <br>
                    <span class="old-price">
                      {{ translation.translate("LandingPageHome", "frameTitle2.1") }}
                    </span>
                  </div>
                </h1> -->
                <h1 id="Possibilities" class="main-title" >
                  {{ translation.translate("LandingPageHome", "frameTitle") }}
                  <br>
                  {{ translation.translate("LandingPageHome", "frameTitle2") }}
                </h1>

              </div>
              <div class="d-flex justify-content-center mt-1">

                <p class="text-center">
                  {{ translation.translate("LandingPageHome", "servise-info") }}
                </p>
              </div>
              <div class="d-flex justify-content-center flex-wrap">

                <button @click="() => { this.$router.push({ name: 'price' }) }" class="noactive-btn  frameBtn mx-5">
                  {{ translation.translate("Modules", "btn-more-info") }}</button>
                <input class="frameBtn main_sign_in" type=button
                  onClick="location.href='https://account.faktura.uz/Login?ReturnUrl=http%3a%2f%2faccount.faktura.uz%2fAuthorize%3fresponse_type%3dcode%26client_id%3d12379127389123%26redirect_uri%3dhttps%253a%252f%252fapp.faktura.uz%252faccount%252fexternallogin%26state%3d%252f%26scope%3d0%252c1%252c2%252c3'"
                  :value="translation.translate('Price', 'signUP')">
              </div>

            </div>


            <!-- <article class="card mb-2 border-mainFrame MainCard" style="max-width: 20rem;">

                  <img src="../assets/images/news.png" alt="Image" class="card-img-top"/>

                  <div class="card-body">
                    <h4 class="card-title">
                      {{ translation.translate("LandingPageHome", "news-title") }}
                    </h4>

                    <div class="gradient"></div>

                    <div class="card-text">
                      <p class="text-p">{{ translation.translate("LandingPageHome", "news") }}</p>
                      <div>
                        <a
                          href="https://bit.ly/3IbgdMd"
                          target="_self"
                          class="btn btn-primary"
                          style="display: flex; justify-content: center; width: 190px; margin: 20px auto 0; border: none; background: rgb(0, 136, 204); font-size: 15px;"
                          >{{ translation.translate("LandingPageHome", "btn-article") }}
                          <img src="https://img.icons8.com/ios-filled/25/ffffff/telegram-app.png" class="ml-2"/></a>
                      </div>
                    </div>
                  </div>

                </article> -->

            <!-- <div class="card--review">
                <div class="green-place">
                  <h4>Оставьте отзыв</h4>
                </div>
                    <p>Значимость этих проблем настолько очевидна?</p>
                    <div class="emojies-box d-flex p-2">
                      <div class="emoji-items ml-3">
                        <b-icon icon="emoji-frown" class="emoji-onclick"></b-icon>
                      </div>
                      <div class="emoji-items ml-2">
                        <b-icon icon="emoji-neutral" class="emoji-onclick emoji-2"></b-icon>
                      </div>
                      <div class="emoji-items ml-2">
                        <b-icon icon="emoji-smile" class="emoji-onclick emoji-3"></b-icon>
                      </div>
                      <div class="emoji-items ml-2">
                        <b-icon icon="emoji-laughing" class="emoji-onclick emoji-4"></b-icon>
                      </div>
                      <div class="emoji-items ml-2">
                        <b-icon icon="emoji-heart-eyes" class="emoji-onclick emoji-5"></b-icon>
                      </div>
                    </div>
                    <div class="linesCard-review"></div>
                    <h5>Значимость этих проблем настолько очевидна?</h5>
                    <textarea class="textarea-box" name="" id="" cols="30" rows="10"></textarea>
                    <div class="cardBTN-box d-flex justify-content-between">
                      <button class="btn-closed">Закрыть</button>
                      <button class="btn-send">Отправить</button>
                    </div>
              </div> -->

            <!-- <div class="card--review">
                  <div class="green-place">
                    <h4>Опросник</h4>
                  </div>
                      <b-progress :value="value" :max="max" :precision="2" show-progress class="green-box"></b-progress>
                      <p class="mb-2">Вопрос: <span>7</span></p>
                      <h5 class="question-text">Любой вопрос на который мы ищем ответ?</h5>
                      <div class="linesCard-review"></div>
                      <div class="messengers-box">
                        <h6 class="light-sms">С другой стороны постоянное информационное</h6>
                        <h6 class="green-sms">С другой стороны постоянное информационно-пропагандистское обеспечение нашей</h6>
                        <h6 class="light-sms">С другой стороны постоянное информационное</h6>
                      </div>
                       <div class="cardBTN-box d-flex justify-content-between">
                      <button class="btn-closed">Назад</button>
                      <button class="btn-send">Дальше</button>
                    </div>
              </div> -->

            <!-- <div class="card--review">
                  <div class="green-place">
                    <h4>Голосование</h4>
                  </div>
                     <h3 class="Vote-h3">Значимость этих проблем настолько очевидна</h3>

                       <div class="cardBTN-box d-flex justify-content-between">
                      <button class="btn-closed">Нет</button>
                      <button class="btn-send">Да</button>
                    </div>
              </div> -->
          </div>
          <div class="card-container">

            <div class="card">
              <div class="d-flex justify-content-center mt-3">
                <img src="../../assets/images/svg/landingPage/interface.svg" width="50px">
              </div>
              <div class="d-flex justify-content-center mt-3 text-center">
                <h5>{{ translation.translate("LandingPageHome", "card-interface-title") }}</h5>
              </div>
              <div class="d-flex justify-content-center mt-3">
                <button @click="() => { this.$router.push({ name: 'videoLessons' }) }" class="dropbtn">{{
                  translation.translate("LandingPageHome", "card-detail") }}</button>
              </div>
            </div>
            <div class="card ">
              <div class="d-flex justify-content-center mt-3">
                <img src="../../assets/images/svg/landingPage/statistics.svg" width="50px">
              </div>
              <div class="d-flex justify-content-center mt-3 text-center">
                <h5>{{ translation.translate("LandingPageHome", "card-statistics-title") }}</h5>
              </div>
              <div class="d-flex justify-content-center mt-3">
                <button @click="() => { this.$router.push({ name: 'posibilities' }) }" class="dropbtn">{{
                  translation.translate("LandingPageHome", "card-detail") }}</button>
              </div>
            </div>
            <div class="card ">
              <div class="d-flex justify-content-center mt-3">
                <img src="../../assets/images/svg/landingPage/settings.svg" width="50px">
              </div>
              <div class="d-flex justify-content-center mt-3 text-center">
                <h5>{{ translation.translate("LandingPageHome", "card-settings-title") }}</h5>
              </div>
              <div class="d-flex justify-content-center mt-3">
                <button @click="() => { this.$router.push({ name: 'modules' }) }" class="dropbtn">{{
                  translation.translate("LandingPageHome", "card-detail") }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import translate from "../../../translation/translate";
export default {
  name: "initial-block",
  components: {

  },
  data() {
    return {
      translation: translate,
    };
  },
  mounted() {
    let navbar = document.getElementsByClassName('container-navbar');
    if (navbar && navbar[0]) {
      navbar[0].className += ' container-green-navbar'
    }
  },
  routChanged() {
    let navbar = document.getElementsByClassName('container-navbar');
    if (navbar && navbar[0]) {
      navbar[0].className = ' container-navbar'
    }
  }
}
</script>

<style>
.container-green-navbar {
  background-color: #F5FFEF !important;
}

.container-green-navbar .navbar-collapse {
  background: #F5FFEF !important;
}

#Possibilities .new-price {
  color: #72BD49;
}

#Possibilities .old-price {
  color: #FF0000;
  text-decoration-line: line-through;
  text-decoration-thickness: 2px;
}</style>