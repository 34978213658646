<template>
  <div id="Landing-page">
    <mainheader ></mainheader>
    <chooseTariffModal></chooseTariffModal>
    <mainbox></mainbox>
    <initialBlock style="margin-bottom: 150px;"></initialBlock>
    <section>
      <!-- boxes -->
      <div class="servise-main">
        <!-- spring decoration -->
        <!-- <div class="position-absolute" style="top:100px; left:-80px;">
          <img src="../assets/images/spring/main-tree.png" width="50%" alt="">
        </div>
        <div id="leaves">
          <i v-for="i in 15" :key="i"></i>
        </div>
        <div id="leaves" style="top: 100px; left: -200px;">
          <i v-for="i in 9" :key="i"></i>
        </div>
        <div id="leaves" style="top: 20px; left: -100px;">
          <i v-for="i in 12" :key="i"></i>
        </div> 
        <div class="position-relative d-flex justify-content-end">
          <div class="position-absolute col-4 col-lg-2 p-0" style="top:-70px; right:0px;">
            <img src="../assets/images/spring/flowers-right-1.png" width="100%" alt="">
          </div>
        </div>
        <div class="position-relative d-flex justify-content-start">
          <div class="position-absolute col-4 col-lg-2 p-0" style="top: 110px; left:0px;">
            <img src="../assets/images/spring/flowers-left-1.png" width="100%" alt="">
          </div>
        </div>-->
        <div class="container">
          <h2>
            {{ translation.translate("LandingPageHome", "servise-h2") }}
          </h2>
          <p class="information-main">
            {{
              translation.translate("LandingPageHome", "document-exchange-p")
            }}
          </p>
          <div class="row d-flex justify-content-center">
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
              <div class="box-servise">
                <h3>
                  {{ translation.translate("LandingPageHome", "Creating-h3") }}
                </h3>
                <p class="box-info">
                  {{
              translation.translate(
                "LandingPageHome",
                "creating-info-box"
              )
            }}
                </p>
                <div class="box-image">
                  <img src="../assets/images/Creation.png" alt="creating.png" />
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
              <div class="box-servise">
                <h3>
                  {{ translation.translate("LandingPageHome", "signing-h3") }}
                </h3>
                <p class="box-info">
                  {{ translation.translate("LandingPageHome", "signing-info") }}
                </p>
                <div class="box-image">
                  <img src="../assets/images/Signing.png" alt="Signing.png" />
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12">
              <div class="box-servise">
                <h3>
                  {{ translation.translate("LandingPageHome", "sending-h3") }}
                </h3>
                <p class="box-info">
                  {{ translation.translate("LandingPageHome", "sending-info") }}
                </p>
                <div class="box-image">
                  <img src="../assets/images/Sending.png" alt="Sending.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- possibilities -->
      <div class="possibilities-main">
        <!-- spring decoration
        <div class="position-relative d-flex justify-content-end">
          <div class="position-absolute col-3 col-lg-1 p-0" style="top:0px; right:0px;">
            <img src="../assets/images/spring/flowers-right-2.png" width="100%" alt="">
          </div>
        </div>
        <div class="position-relative d-flex justify-content-start">
        <div class="position-absolute col-4 col-lg-2 p-0" style="top: 200px; left:0px;">
          <img src="../assets/images/spring/flowers-right-1.png" style="transform: scaleX(-1);" width="100%" alt="">
        </div>
      </div> -->
          <div class="container">
          <div class="box-possibilities back-tr">
            <h3 class="text-center d-none-991">
              {{ translation.translate("LandingPageHome", "possibilities-h3") }}
            </h3>
            <p class="text-center mb-5 pb-5 d-none-991">
              {{ translation.translate("LandingPageHome", "Advantages-p") }}
            </p>
            <div class="row">
              <div class="col-lg-6">
                <div class="left-box">
                  <h3>
                    {{
              translation.translate("LandingPageHome", "Advantages-h3")
            }}
                  </h3>
                  <p>
                    {{
                translation.translate(
                  "LandingPageHome",
                  "possibilities-info"
                )
              }}
                  </p>
                  <router-link @click="callGtag('Возможности,opportunities_button,click')"
                    :to="{ name: 'posibilities' }" class="btnPos a-dec-none">{{
              translation.translate(
                "LandingPageHome",
                "btnGoToFacilities"
              )
            }}</router-link>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="right-box">
                  <img src="../assets/images/Possibilities.png" alt="possibilities.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- information blocks -->
      <div class="information-block">
        <!-- spring decoration
        <div class="position-relative d-flex justify-content-end">
          <div class="position-absolute col-4 col-lg-2 p-0" style="top:270px; right:0px;">
            <img src="../assets/images/spring/flowers-left-1.png" style="transform: scaleX(-1);" width="100%" alt="">
          </div>
        </div>
        <div class="position-relative d-flex justify-content-start">
        <div class="position-absolute col-2 col-lg-1 p-0" style="top: 570px; left:0px;">
          <img src="../assets/images/spring/flowers-right-2.png" style="transform: scaleX(-1);" width="100%" alt="">
        </div>
      </div> -->
        <div class="container">
          <div class="row">
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-6">
              <div class="info-item">
                <b-icon icon="clipboard-check"></b-icon>
                <h3 class="items-title">
                  {{
                translation.translate(
                  "LandingPageHome",
                  "Harmonization-title"
                )
              }}
                </h3>
                <p class="items-info">
                  {{
                translation.translate(
                  "LandingPageHome",
                  "Harmonization-info"
                )
              }}
                </p>
              </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-6">
              <div class="info-item">
                <b-icon icon="diagram-3"></b-icon>
                <h3 class="items-title">
                  {{
                translation.translate("LandingPageHome", "Routing-title")
              }}
                </h3>
                <p class="items-info">
                  {{ translation.translate("LandingPageHome", "Routing-info") }}
                </p>
              </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-6">
              <div class="info-item">
                <b-icon icon="people"></b-icon>
                <h3 class="items-title">
                  {{
              translation.translate("LandingPageHome", "employees-title")
            }}
                </h3>
                <p class="items-info">
                  {{
                translation.translate("LandingPageHome", "employees-info")
              }}
                </p>
              </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-6">
              <div class="info-item">
                <b-icon icon="code-slash"></b-icon>
                <h3 class="items-title">
                  {{
                translation.translate(
                  "LandingPageHome",
                  "integration-title"
                )
              }}
                </h3>
                <p class="items-info">
                  {{
                translation.translate("LandingPageHome", "Integration-info")
              }}
                </p>
              </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-6">
              <div class="info-item">
                <b-icon icon="building"></b-icon>
                <h3 class="items-title">
                  {{
                translation.translate("LandingPageHome", "branches-title")
              }}
                </h3>
                <p class="items-info">
                  {{
                translation.translate("LandingPageHome", "branches-info")
              }}
                </p>
              </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-6">
              <div class="info-item">
                <b-icon icon="archive"></b-icon>
                <h3 class="items-title">
                  {{ translation.translate("LandingPageHome", "arxiv-title") }}
                </h3>
                <p class="items-info">
                  {{ translation.translate("LandingPageHome", "arxiv-info") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- KPK -->
      <SectionFlickity></SectionFlickity>
      <!-- Gibrit pochta -->
      <div class="possibilities-main">
        <!-- spring decoration
        <div class="position-relative d-flex justify-content-end">
          <div class="position-absolute col-4 col-lg-2 p-0" style="top:-20px; right:0px;">
            <img src="../assets/images/spring/flowers-right-1.png"  width="100%" alt="">
          </div>
        </div>
        <div class="position-relative d-flex justify-content-start">
        <div class="position-absolute col-4 col-lg-2 p-0" style="top: 510px; left:0px;">
          <img src="../assets/images/spring/flowers-left-1.png"  width="100%" alt="">
        </div>
      </div> -->
        <div class="container">
          <div class="box-possibilities padNone back-tr">
            <div class="row">
              <div class="col-lg-6">
                <div class="right-box center-el">
                  <img src="../assets/images/gibrit pochta.png" alt="possibilities.png" />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="left-box pad-left-pos">
                  <h3>
                    {{
              translation.translate("LandingPageHome", "gibrit-title")
            }}
                  </h3>
                  <p class="p-right-20">
                    {{
                translation.translate("LandingPageHome", "gibrit-info")
              }}
                  </p>
                  <a @click="callGtag('Гибридная почта,hybrid_button,click')"
                    href="https://account.faktura.uz/Login?ReturnUrl=http%3a%2f%2faccount.faktura.uz%2fAuthorize%3fresponse_type%3dcode%26client_id%3d12379127389123%26redirect_uri%3dhttps%253a%252f%252fapp.faktura.uz%252faccount%252fexternallogin%26state%3d%252f%26scope%3d0%252c1%252c2%252c3"
                    class="btnPos a-dec-none">{{ translation.translate("LandingPageHome", "btnTry") }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Mobile phone -->
      <div class="possibilities-main">
        <div class="container">
          <div class="box-possibilities height-auto pad50-20 shadow-rgba300 shadow-none-991">
            <div class="row">
              <div class="col-lg-6">
                <div class="left-box">
                  <h3 class="mt-85M">
                    {{
              translation.translate("LandingPageHome", "mobile-title")
            }}
                  </h3>
                  <p>
                    {{
                translation.translate("LandingPageHome", "mobile-info")
              }}
                  </p>
                  <div class="d-flex justify-content-between width-80precent">
                    <div class="download-box mt-2">
                      <div class="download-btn">
                        <a href="https://apps.apple.com/us/app/faktura/id1454146398" class="a-dec-none">
                          <div class="d-flex justify-content-between align-items-center">
                            <div>
                              <img src="../assets/images/apple.png" alt="png" class="playMarket" />
                            </div>
                            <div class="d-flex flex-column align-items-between">
                              <p class="download-text">Загрузите в</p>
                              <p class="download-text-main">App Store</p>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="download-box mt-2">
                      <div class="download-btn">
                        <a href="https://play.google.com/store/apps/details?id=uz.faktura&hl=ru" class="a-dec-none">
                          <div class="d-flex justify-content-between align-items-center">
                            <div>
                              <img src="../assets/images/play-market-png.jpg" alt="png" class="playMarket" />
                            </div>
                            <div class="d-flex flex-column align-items-between">
                              <p class="download-text">Загрузите в</p>
                              <p class="download-text-main">Play Market</p>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="download-box mt-2">
                      <div class="download-btn">
                        <a href="https://appgallery.huawei.com/#/app/C104519173" class="a-dec-none">
                          <div class="d-flex justify-content-between align-items-center ">
                            <div>
                              <img src="../assets/images/AppGallary.png" alt="png" class="playMarket" />
                            </div>
                            <div class="d-flex flex-column align-items-between">
                              <p class="download-text">Загрузите в</p>
                              <p class="download-text-main">AppGallary</p>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">

                <div class="right-box m-auto">
                  <img src="../assets/images/Mobile APP.png" alt="possibilities.png" class="width-365px" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- partners -->
      <sliderClients></sliderClients>
    </section>

    <mainfooter></mainfooter>
  </div>
</template>

<script>
import translate from "../../translation/translate";
import mainbox from "./mainbox.vue";
import mainheader from "./mainheader.vue";
import initialBlock from "./initialComponents/initialBlock.vue"
import mainfooter from "./mainfooter.vue";
import SectionFlickity from "./SectionFlickity.vue"
import sliderClients from "./initialComponents/slideCients.vue"
import mixins from "./../mixin";
import chooseTariffModal from "./modal/ChooseTariffModal.vue";
import ChooseTariffModal from "./modal/ChooseTariffModal.vue";

export default {
  name: "Landing-page",
  metaInfo() {
    return {
      title: "Электронный документооборот в Узбекистане - внедрение ЭДО",
      meta: [
        {
          name: "description",
          content:
            " Ведение электронного документооборота(ЭДО) в Узбекистане.⏩ Faktura.EDO - программа электронного документооборота онлайн. ⏩ Адекватная цена. ☎️: +998 (71) 200-00-13.",
        },
        {
          name: "keywords",
          content:
            "faktura edo, faktura, faktura uz, soliq faktura, faktura soliq uz, e faktura, e faktura uz, soliq servis uz faktura, soliq servis faktura, faktura ru, e faktura soliq uz, e faktura soliq, soliq servis uz faktura ru, uzasbo faktura, soliqservis uz faktura, online faktura uz, my soliq, my soliq uz, soliq servis, soliq uz электронная, hisobot soliq uz, may soliq, may soliq uz, tasnif soliq uz, фактура, счет фактура, фактура уз, солик фактура, фактура узбекистана, счет фактура узбекистан, е фактура, фактура солик уз, электронный фактура, электронные счета фактуры, солик сервис уз фактура, сервис фактура, выставить фактуру",
        },
      ],
    };
  },
  components: {
    SectionFlickity,
    mainheader,
    mainbox,
    initialBlock,
    mainfooter,
    sliderClients,
    chooseTariffModal,
    ChooseTariffModal
  },
  mixins: [mixins],
  data() {
    return {
      translation: translate,
      screenWith: window.innerWidth,
      spinerInProgers: false
    };
  },
  
  methods: {
    changeWebkitKeyframes(width, height) {
  // Find or create the <style> element
      let styleElement = document.querySelector('style#dynamic-keyframes');
      if (!styleElement) {
        styleElement = document.createElement('style');
        styleElement.type = 'text/css';
        styleElement.id = 'dynamic-keyframes';
        document.head.appendChild(styleElement);
      }
      let keyFrames = `@-webkit-keyframes falling {

                            0% {
                              -webkit-transform:
                                translate3d(-50px, 0, 0) rotate(0deg);
                            }

                            100% {
                              -webkit-transform:
                                translate3d(${width}px, ${height}px, 0) rotate(90deg);
                              opacity: 0;
                            }
                            }

                            @-webkit-keyframes falling3 {
                            0% {
                              -webkit-transform:
                                translate3d(0, 0, 0) rotate(-20deg);
                            }

                            100% {
                              -webkit-transform:
                                translate3d(${width}px, ${height/2}px, 0) rotate(-70deg);
                              opacity: 0;
                            }
                            }

                            @-webkit-keyframes falling2 {
                            0% {
                              -webkit-transform:
                                translate3d(0, 0, 0) rotate(90deg);
                            }

                            100% {
                              -webkit-transform:
                                translate3d(${width}px, ${height/4}px, 0) rotate(0deg);
                              opacity: 0;
                            }
                            }`;

      // Apply the new keyframes to the <style> element
      styleElement.innerHTML = keyFrames;
    }
  },

  created() {
    this.spinerInProgerss = true;
  },
  mounted() {
    if (this.screenWith > 768) this.$bvModal.show('bv-modal-example')
    this.spinerInProgerss = false;
  console.log(window.outerHeight);
     this.changeWebkitKeyframes(window.outerWidth- (window.outerWidth/100*25), window.outerHeight*3/4);
  },

};
</script>

<style>
.faktura-new-year-light-center {
  width: 160px;
  position: absolute;
  top: 0px;
  left: 50px;
}

.faktura-new-year-light-left {
  width: 242px;
  position: absolute;
  top: -58px;
  right: -45px;
}

.faktura-new-year-light-right {
  width: 242px;
  position: absolute;
  top: -58px;
  left: -45px;
}

@media(max-width: 1010px) {
  .faktura-new-year-light-left {
    display: none;
  }
}

@media (max-width: 766px) {
  .modal {
    display: none !important;
  }

  #bv-modal-example___BV_modal_backdrop_ {
    display: none !important;
  }
}




</style>
