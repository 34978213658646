<template>
<div id="slideClient">
    <div class="partners-title">
        <div class="container">
            <h2>
                {{ translation.translate("LandingPageHome", "partners-title") }}
            </h2>
            <p class="information-main text-center mt-2">
                {{ translation.translate("LandingPageHome", "partners-info") }}
            </p>
            <div class="row"></div>
        </div>
    </div>
    <flickity ref="flickity" :options="flickityOptions">
        <div class="carousel-cell">
            <img src="../../assets/images/Partners/ucell.png" alt="" class="image-partners" />
        </div>
        <div class="carousel-cell">
            <img src="../../assets/images/Partners/pepsi.png" alt="" class="image-partners" />
        </div>
        <div class="carousel-cell">
            <img src="../../assets/images/Partners/Coca-Cola.png" alt="" class="image-partners" />
        </div>
        <div class="carousel-cell">
            <img src="../../assets/images/Partners/agro_bank.png" alt="" class="image-partners" />
        </div>
        <div class="carousel-cell">
            <img src="../../assets/images/Partners/korzinka_logo_new.png" alt="" class="image-partners" />
        </div>
        <div class="carousel-cell">
            <img src="../../assets/images/Partners/ipoteka_bank.png" alt="" class="image-partners" />
        </div>
        <div class="carousel-cell">
            <img src="../../assets/images/Partners/xalqbank.png" alt="" class="image-partners" />
        </div>
        <div class="carousel-cell">
            <img src="../../assets/images/Partners/sqb.png" alt="" class="image-partners" />
        </div>
        <div class="carousel-cell">
            <img src="../../assets/images/Partners/article-original.png" alt="" class="image-partners" />
        </div>
        <div class="carousel-cell">
            <img src="../../assets/images/Partners/ipak.png" alt="" class="image-partners" />
        </div>
        <div class="carousel-cell">
            <img src="../../assets/images/Partners/NBU-01.png" alt="" class="image-partners" />
        </div>
        <div class="carousel-cell">
            <img src="../../assets/images/Partners/Temir-yollar.png" alt="" class="image-partners" />
        </div>
        <div class="carousel-cell">
            <img src="../../assets/images/Partners/qqb.png" alt="" class="image-partners" />
        </div>
        <div class="carousel-cell">
            <img src="../../assets/images/Partners/ASia.png" alt="" class="image-partners" />
        </div>
        <div class="carousel-cell">
            <img src="../../assets/images/Partners/uztelecom.png" alt="" class="image-partners" />
        </div>
        <div class="carousel-cell">
            <img src="../../assets/images/Partners/mobiuz.png" alt="" class="image-partners" />
        </div>
        <div class="carousel-cell">
            <img src="../../assets/images/makro.png" alt="" class="image-partners" />
        </div>
    </flickity>
</div>
</template>

<script>
import flickity from "vue-flickity";
import translate from "../../../translation/translate";

export default {
    name: "slideClients",
    components: {
        flickity
    },
    data() {
        return {
            translation: translate,
            flickityOptions: {
                wrapAround: true,
            },
        };
    },
}
</script>
