<template>
    <div id="SectionFlickity">

      <div class="container d-flex flex-column align-items-center">
        <h3>{{translation.translate("SectionFlickity", "title")}}</h3>
        <p class="dscMonitoring">{{translation.translate("SectionFlickity", "description")}}</p>
      </div>

         <flickity ref="flickity" class="carousel" :options="flickityOptions">
          <div class="carousel-cell">
            <div class="frame-block">
              <div class="InfoBox">
                  <div class="d-flex justify-content-center align-items-center">
                    <img src="../assets/images/1.png" alt="">
                  </div>
                  <p class="InfoBoxText">{{translation.translate("SectionFlickity", "text3")}}</p>
              </div>
            </div>  
          </div>
          <div class="carousel-cell">
            <div class="frame-block">
              <div class="InfoBox">
                  <div class="d-flex justify-content-center align-items-center">
                    <img src="../assets/images/2.png" alt="">
                  </div>
                  <p class="InfoBoxText fzText2">{{translation.translate("SectionFlickity", "text2")}}</p>
              </div>
            </div>  
          </div>
          <div class="carousel-cell">
            <div class="frame-block">
              <div class="InfoBox">
                  <div class="d-flex justify-content-center align-items-center">
                    <img src="../assets/images/3.png" alt="">
                  </div>
                  <p class="InfoBoxText">{{translation.translate("SectionFlickity", "text1")}}</p>
              </div>
            </div>  
          </div>
          <div class="carousel-cell">
            <div class="frame-block">
              <div class="InfoBox">
                  <div class="d-flex justify-content-center align-items-center">
                    <img src="../assets/images/4.png" alt="">
                  </div>
                  <p class="InfoBoxText">{{translation.translate("SectionFlickity", "text4")}}</p>
              </div>
            </div>  
          </div>


        </flickity>

        <div class="container MonitoringCard1199">
          <div class="row">
          <div class="col-lg-6 col-md-6 col-12"> 
            <div class="frame-block">
              <div class="InfoBox">
                  <div class="d-flex justify-content-center align-items-center">
                    <img src="../assets/images/1.png" alt="">
                  </div>
                  <p class="InfoBoxText">{{translation.translate("SectionFlickity", "text3")}}</p>
              </div>
            </div>
            </div>
          <div class="col-lg-6 col-md-6 col-12">
             <div class="frame-block">
              <div class="InfoBox">
                  <div class="d-flex justify-content-center align-items-center">
                    <img src="../assets/images/2.png" alt="">
                  </div>
                  <p class="InfoBoxText fzText2">{{translation.translate("SectionFlickity", "text2")}}</p>
              </div>
            </div> 
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <div class="frame-block">
              <div class="InfoBox">
                  <div class="d-flex justify-content-center align-items-center">
                    <img src="../assets/images/3.png" alt="">
                  </div>
                  <p class="InfoBoxText">{{translation.translate("SectionFlickity", "text1")}}</p>
              </div>
            </div> 
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <div class="frame-block">
              <div class="InfoBox">
                  <div class="d-flex justify-content-center align-items-center">
                    <img src="../assets/images/4.png" alt="">
                  </div>
                  <p class="InfoBoxText">{{translation.translate("SectionFlickity", "text4")}}</p>
              </div>
            </div> 
          </div>
        </div>
        </div>

       
        
         

    </div>
</template>

<script>

import translate from '../../translation/translate'
import flickity from "vue-flickity";

export default {
  name: 'App',
  components:{
    flickity
    // modal
  },
  data() {
    return {
      translation: translate,
      flickityOptions: {
        wrapAround: true,
      },
    }
  },

}

</script>


<style scoped>

@media (max-width: 1199px) {
    section .frame-block{
      margin: 30px auto !important;
      width: 90% !important;
    }

  .fzText2{
    font-size: 16px !important;
  }
}

h3{
  margin: 100px 20px 10px;
  text-align: center;
}

img{
  width: 70px;
}
.InfoBoxText{
  font-size: 16px;
  font-weight: 700;
  margin-top: 10px;
  text-align: center;
}
.fzText2{
  font-size: 13px;
}

.dscMonitoring{
  text-align: center;
  padding: 0 10 col-md-6 col-120px;
}

/* ====== Slider ===== */
 .carousel{
  width: 100%;
  margin-top: 50px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

section .frame-block {
  width: 55%;
  min-width: 250px;
  height: 180px;
  box-shadow: 0 8px 20px rgb(214, 214, 214);
  padding: 15px;
  border-radius: 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 100px;
}
/* Carousel */

.flickity-page-dots{
  margin-bottom: 200px;
}

.carousel {
  background: transparent;;
}

.carousel-cell {
  width: 60%;
  height: auto;
  margin-right: 10px;
}

.MonitoringCard1199{
  margin: auto;
}
</style>